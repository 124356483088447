import { BOOSTAD_API } from '@/constants.js';
import { STATS_SERVER } from '@/constants.js';

import axios from 'axios';
import get from 'lodash/get';
import { Buffer } from 'buffer';
import { get_header } from '../components/f';

const useBasicAuth = true; // else uses access-token

const CLIENT_ID = 'boostad-admin';
const CLIENT_SECRET = 'd1967cd7-7022-4358-a8fa-a19b82528900';
//const TOKEN_URL = 'https://ip-prd-identity-web.azurewebsites.net/connect/token';
//var CUSTOMER_URL = '';
//var id_token = null;

export default {
  CUSTOMER_URL() {
    var customer_ext = '';
    customer_ext = get(
      window.MyStore.state.azurestore,
      'serviceOrder.customer_ext',
      null
    );

    if (customer_ext == null)
      customer_ext = get(
        window.MyStore.state.azurestore,
        'userInfo.customer_ext',
        null
      );

    if (customer_ext == null) customer_ext = 'beo';

    return (
      'https://' +
      customer_ext +
      '-prd-customer-web.azurewebsites.net/RA/Customer/Services/'
    );
  },

  async getAuthorizationHeader() {
    var authHdr = '';
    if (useBasicAuth === true) {
      authHdr =
        'Basic ' +
        Buffer.from(CLIENT_ID + ':' + CLIENT_SECRET).toString('base64');
    } else {
      authHdr = 'Bearer ' + (await this.getToken());
    }
    return authHdr;
  },

  async postBackEnd(funktion, obj, jwtToken) {
    var post_data = JSON.stringify(obj);

    const url = this.CUSTOMER_URL() + funktion;

    //console.log('===>>>  To Azure Backend', url, post_data);

    return await axios
      .post(url, post_data, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + jwtToken,
        },
      })
      .then((res) => {
        //console.log('====>> From Azure backend', res);
        //throw new Error('kaboom');
        return res;
        // return res;
      })
      .catch((e) => {
        console.log('ERROR from backend', JSON.stringify(e));
        return { status: 0, error: e.message };
      });
  },

  async getBackEnd(funktion, jwtToken) {
    const url = this.CUSTOMER_URL() + funktion;
    // console.log('===>>>  To Azure backend get', url);

    return await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          // 'Authorization': 'Bearer ' + token,
          // Authorization: await this.getAuthorizationHeader(),
          Authorization: 'Bearer ' + jwtToken,
        },
      })
      .then((res) => {
        //console.log('<<== from azure backend get', res);
        if (res && res.data) return get(res, 'data', null);
        else return res.status;
      })
      .catch((e) => {
        //TODO: generell felhantering
        console.log('ERROR from backend', JSON.stringify(e));
        return null;
      });
  },

  async postBoostad(funktion, obj) {
    const headers = get_header();
    headers.Accept = 'application/json';
    var post_data = JSON.stringify(obj);

    const url = BOOSTAD_API + funktion;
    //console.log('===>>>  To backend Boostad', funktion, url, post_data);

    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        //console.log('<<== from backend Boostad', funktion, res);
        return get(res, 'data', []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },

  async getBoostad(funktion) {
    // var post_data = JSON.stringify(obj);
    const headers = get_header();
    headers.Accept = 'application/json';

    const url = BOOSTAD_API + funktion;

    // console.log('===>>>  Boostad get', funktion, url);

    return await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        //console.log('<<== boostad anws', funktion, res);
        return get(res, 'data', []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },
  async postStatsServer(funktion, obj) {
    const headers = get_header();
    if (funktion == '/api/SendSms') {
      headers.session = process.env.VUE_APP_SENDSMS_SESSION;
    }
    headers.Accept = 'application/json';
    var post_data = JSON.stringify(obj);
    const url = STATS_SERVER + funktion;
    //console.log('===>>>  To statsserver', headers, funktion, url, post_data);
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        //console.log('<<== from statsserver', funktion, res);
        return res;
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },
  async getImageServer(funktion) {
    // const headers = get_header();
    //  headers.Accept = 'application/json';
    const url = STATS_SERVER + funktion;
    //console.log('===>>>  To statsserver', funktion, url);

    return await axios
      .get(url, {
        // headers: headers,
      })
      .then((res) => {
        //console.log('<<== from statsserver', res.data);
        return get(res, 'data', null);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },
};
