<template>
  <div>
    <v-container>
      <Description
        :url="estateImage"
        :header="header"
        :description="description"
      />

      <v-row>
        <!--kaka på kaka, houses borde inte behöva stå fler ggr i koden-->
        <v-card flat class="cardStyle">
          <div v-if="estateAddress">
            <v-label
              ><b>{{ estateAddress }}</b></v-label
            >
            <div
              v-if="biddingEnabled && this.theSource == 'Mspecs'"
              class="pauseContainer"
            >
              <span class="pauseContent left" v-if="setPause"
                >Tjänsten för Budgivning med BankID är pausad. Budgivningen
                administreras i mäklarsystemet.</span
              >

              <span class="pauseContent left" v-else
                >Budgivning med BankID är aktiverat. Budgivningen administreras
                i mäklarsystemet.</span
              >

              <div class="pauseContent right">
                <span v-if="showEstateList == false">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                        @click="switchToLocalUser"
                      >
                        <img class="houses" />
                      </v-btn>
                    </template>
                    <span>Lista alla objekt</span>
                  </v-tooltip>
                </span>
              </div>
            </div>
            <div v-else-if="biddingEnabled" class="pauseContainer">
              <div class="pauseContent left">
                <span class="pauseText" v-if="setPause"
                  >Återuppta budgivningen</span
                >
                <span class="pauseText" v-else>Pausa budgivningen</span>
                <label class="switch">
                  <input
                    @change="changePause"
                    :checked="setPause"
                    type="checkbox"
                  />

                  <div class="slider round"></div>
                </label>
              </div>

              <div class="pauseContent right">
                <span v-if="showEstateList == false">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                        @click="switchToLocalUser"
                      >
                        <img class="houses" />
                      </v-btn>
                    </template>
                    <span>Lista alla objekt</span>
                  </v-tooltip>
                </span>
              </div>
            </div>
            <div v-if="biddingEnabled == false" class="pauseContainer">
              <div class="pauseContent left">
                <span
                  >Budgivning med BankID är inte aktiverat. Budgivningen startas
                  i mäklarsystemet.</span
                >
              </div>

              <div class="pauseContent right">
                <span v-if="showEstateList == false">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                        @click="switchToLocalUser"
                      >
                        <img class="houses" />
                      </v-btn>
                    </template>
                    <span>Lista alla objekt</span>
                  </v-tooltip>
                </span>
              </div>
            </div>
          </div>
          <div v-else>
            <span v-if="this.theSource == 'Mspecs'"
              >Välj ett objekt för se status på budgivningen.</span
            >
            <span v-else>Välj ett objekt för att pausa budgivningen.</span>
          </div>
        </v-card>
      </v-row>
      <!--budgivare-->
      <v-row>
        <v-card v-if="bidders && bidders.length > 0" class="cardStyle" flat>
          <v-row v-if="!$vuetify.breakpoint.xs">
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <b> <label class="secondaryText">Budgivare</label></b>
            </v-col>

            <v-col cols="12" xl="3" lg="3" md="3" sm="3">
              <b> <label class="secondaryText">Dela länk</label></b>
            </v-col>

            <v-col cols="12" xl="3" lg="3" md="3" sm="3">
              <b> <label class="secondaryText">Logga in som</label></b>
            </v-col>
          </v-row>
          <v-row align-content="start" v-for="item in bidders" :key="item.Uid">
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <v-label>{{ item.FirstName + ' ' + item.LastName }}</v-label>
            </v-col>

            <v-col cols="12" xl="3" lg="3" md="3" sm="3">
              <v-btn @click="prepareSMS(item, 'Bidding')" text>
                <v-icon color="var(--icon-color)">share</v-icon></v-btn
              >
            </v-col>

            <v-col cols="12" xl="3" lg="3" md="3" sm="3">
              <v-btn text @click="loginAsUser(item, 'Bidding')"
                ><v-icon :disabled="item?.Ssn == null" color="var(--icon-color)"
                  >login</v-icon
                ></v-btn
              >
            </v-col>
          </v-row>
        </v-card>
        <br />
      </v-row>
      <!--slut budgivare-->
      <EstateList
        module="bidding"
        @estateSelected="onSelectedEstate"
        v-if="estateAddress == null || showEstateList == true"
      />
    </v-container>
    <!-- dialog skicka sms  -->
    <v-dialog v-model="smsDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="boxText">
            Vill du skicka sms till {{ this.smsName }}?
          </span>
        </v-card-title>

        <v-card-text>
          <span class="boxText">
            OBS! Budgivningslänken skickas normalt från mäklarsystemet. <br />
            Vill du ändå skicka den häifrån läggs länken automatiskt till i
            sms:et.
          </span>
          <br />
          <br />
          <v-textarea dense rows="2" outlined v-model="msgText"> </v-textarea>
          <br />
          <v-row>
            <v-btn @click="copyLink()" text>
              <v-icon>mdi-content-copy</v-icon>Kopiera länken</v-btn
            >
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="smsDialog = false" outlined>Skicka inte</v-btn>
          <v-btn @click="sendSMS" outlined>Skicka SMS</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- slut -->
  </div>
</template>

<script>
import mixin from '@/mixin.js';
import Description from '@/components/description.vue';
import EstateList from '@/components/moduleButtons/estateList.vue';
import get from 'lodash/get';
//import rest2Api from '@/api/rest2Api.js';

export default {
  name: 'Bidding-Admin',
  mixins: [mixin],
  data() {
    return {
      header: 'Budgivning med BankID',
      showEstateList: false,
      smsDialog: false,
      smsName: '',
      smsNumber: '',
      smsLink: '',
      smsText: '',
      msgText: 'Hej! Här kommer en inloggningslänk till budgivningen',
    };
  },
  watch: {
    estateID() {
      var image = get(this.$store.state.azurestore, 'imgUrl', null);
      if (!image) {
        var params = {
          estateID: this.estateID,
          org: this.org,
          source: this.theSource,
        };
        this.$store.dispatch('azurestore/getImageUrl', params);
      }
    },
  },
  beforeMount() {},

  mounted() {
    var image = get(this.$store.state.azurestore, 'imgUrl', null);
    if (!image) this.$store.dispatch('azurestore/setInitialBiddingImgUrl');
  },
  computed: {
    estateAddress() {
      var address = get(
        this.$store.state.azurestore,
        'estate.Address.StreetAddress',
        null
      );
      if (address) address = address.toUpperCase();
      return address;
    },
    biddingEnabled() {
      var settings = get(this.$store.state.azurestore, 'estate.Settings', []);
      if (settings.BiddingEnabled == true) {
        return true;
      } else return false;
    },

    estateID() {
      return get(
        this.$store.state.azurestore,
        'estate.ExternalIDs[0].Id',
        null
      );
    },
    estateImage() {
      return get(this.$store.state.azurestore, 'imgUrl', null);
    },

    setPause() {
      var settings = get(this.$store.state.azurestore, 'estate.Settings', []);
      if (settings.BiddingPaused == true) {
        return true;
      } else return false;
    },

    description() {
      if (this.theSource == 'Mspecs')
        return 'Budgivningen administeras i mäklarsystemet, här kan du se om Budgivning med BankID är aktiverat eller inte.';
      else
        return 'Budgivningen administeras i mäklarsystemet, här kan endast pausning av budgivningen ske.';
    },
    bidders() {
      //kommer som interests men vi filtrerar fram bidders när vi hämtar bostaden
      var getBidders = get(
        this.$store.state.azurestore,
        'estate.Bidders',
        null
      );
      return getBidders;
    },
  },
  methods: {
    changePause(e) {
      var obj = new Object();
      //  var visibility = 'Show';
      if (e.target.checked == true) obj.BiddingPaused = true;
      else if (e.target.checked == false) obj.BiddingPaused = false;

      this.$store
        .dispatch('azurestore/changeEstateSettings', obj)
        .then((res) => {
          if (res && res.status == 0) {
            this.alertDialogNoCancel(
              'Fel',
              'Något gick fel: ' + res.error,

              () => console.log('user ok')
            );
          }
        });
    },
    onSelectedEstate() {
      this.showEstateList = true;
      window.scrollTo(0, 0);
    },

    switchToLocalUser() {
      if (get(this.$store.state.infopingstore, 'localUser', 'false') == false) {
        this.$store
          .dispatch(
            'azurestore/getUserFromSession',
            get(this.$store.state.infopingstore, 'session', null)
          )
          .then(() => {
            this.$store.dispatch('infopingstore/addLocalUser');
          });
      }
      this.showEstateList = true;
    },

    async prepareSMS(item, type) {
      if (type == 'Buy' && this.setAccessBuyer == false) {
        this.alertDialogNoCancel(
          'Access avstängd',
          'Köparen kan inte logga in på Mina sidor, sätt på åtkomst längre ner på sidan',
          () => console.log('user ok')
        );
      } else if (type == 'Sell' && this.setAccessSeller == false) {
        this.alertDialogNoCancel(
          'Access avstängd',
          'Säljaren kan inte logga in på Mina sidor, sätt på åtkomst längre ner på sidan',
          () => console.log('user ok')
        );

        //kolla om länk finns och om det är samma kontakt
      } else if (
        this.smsNumber &&
        this.smsLink.includes(item.ExternalIDs[0].Id)
      )
        this.smsDialog = true;
      else {
        //annars hämta länk
        this.createLink(item, type).then((res) => {
          if (res) {
            this.smsLink = res;
            this.smsDialog = true;
          }
        });
      }
    },

    async createLink(item, type) {
      var estateUid = get(this.$store.state.azurestore, 'estate.Uid', []);
      var path = this.theSource + '/' + item.Uid + '/' + type + '/' + estateUid;
      var answer = await this.$store.dispatch('azurestore/getLoginUrl', path);
      if (answer) {
        const url = new URL(answer);
        this.loginUrl = 'https://' + url.host;
        this.smsNumber = item.Mobile;
        this.smsName = item.FirstName + ' ' + item.LastName;
        // this.smsDialog = true;
      } else {
        this.alertDialogNoCancel(
          'Fel',
          'Det gick inte att skapa inloggningslänk, försök igen',
          () => console.log('user ok')
        );
      }

      return answer;
    },
    sendSMS() {
      if (this.smsNumber) {
        this.smsDialog = false;
        //remove .. in the end of the link
        //this.smsLink = this.smsLink.replace(/\.{2,}$/g, '');
        var params = {
          link: this.smsLink,
          phone_number: this.smsNumber,
          name: this.Name,
          message: this.msgText,
        };
        this.$store.dispatch('azurestore/sendSMS', params).then((res) => {
          if (res == 'ok')
            this.alertDialogNoCancel(
              'Skickat',
              'Ett sms med budgivningslänk är skickat',
              () => console.log('user ok')
            );
          else
            this.alertDialogNoCancel(
              'Något gick fel',
              'SMS:et skickades inte. Försök igen eller kopiera länken och skicka den via mail. Felkod: ' +
                res,
              () => console.log('user ok')
            );
        });
      } else {
        this.alertDialogNoCancel(
          'Mobilnummer saknas',
          'Uppdatera kontakten i mäklarsystemet och tryck på Uppdatera-knappen längst ner på fliken Mina sidor.',
          () => console.log('user ok')
        );
      }
    },

    async loginAsUser(item, type) {
      if (item) {
        if (item?.Ssn != null) {
          var estateUid = get(this.$store.state.azurestore, 'estate.Uid', []);
          var contactUid = get(item, 'Uid', '');

          if (!this.loginUrl) {
            await this.createLink(item, type);
          }

          if (this.loginUrl) {
            this.$store
              .dispatch('azurestore/createLogin', contactUid)
              .then((res) => {
                window.open(
                  this.loginUrl +
                    '/' +
                    type +
                    '/' +
                    estateUid +
                    '?access_token=' +
                    res
                );
              });
          }
        } else if (item.Ssn == null) {
          this.alertDialogNoCancel(
            'Personnummer saknas',
            'Har användaren personnummer i mäklarsystemet? Det går inte att simulera en inloggning utan personnummer',
            () => console.log('user ok')
          );
        }
      } else {
        this.alertDialogNoCancel(
          '',
          'Hittar inte användaren i mäklarsystemet',
          () => console.log('user ok')
        );
      }
    },
  },
  components: { Description, EstateList },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 24px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d2d2d2;

  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: white;

  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #999;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(45px);
}

/*------ ADDED CSS ---------*/
.slider:after {
  content: '';
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 9px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider:after {
  content: 'Pausad';
  margin-left: -5px;
}

/*--------- END --------*/

.pauseContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}

.pauseContent {
  flex-grow: 1;
  text-align: center;
  display: flex;
}

.pauseContent.left {
  text-align: left;
}
.pauseText {
  margin-right: 30px;
}

.pauseContent.right {
  text-align: right;
  display: block;
}
.houses {
  width: 50px;
  content: var(--houses-img);
}
</style>
