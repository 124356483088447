<template>
  <div class="navBar">
    <div v-for="(step, i) in steps" :key="step.id">
      <button
        @click="goTo(step.id)"
        :class="{ active: activeStep === step.id, navButton: true }"
      >
        {{ step.name }}
      </button>
      <span v-if="i != steps.length - 1" class="navBarDivider"></span>
    </div>
  </div>
</template>
<script>
import mixin from '@/mixin.js';
import get from 'lodash/get';
export default {
  name: 'NavBar',
  mixins: [mixin],
  data() {
    return {
      activeStep: null,
    };
  },

  beforeMount() {
    if (this.$route.name == 'BrokerWeb' || this.$route.name == 'MyPages')
      this.activeStep = 2;
    else if (this.$route.name == 'Viewing') this.activeStep = 3;
    else if (this.$route.name == 'Nps') this.activeStep = 5;
    if (this.$route.name == 'BrokerWeb') this.activeStep = 2;
    //  if (this.$route.name == 'BestComment') this.activeStep = 7;
    if (this.$route.name == 'Bidding') this.activeStep = 4;
    if (this.$route.name == 'Shortcuts') this.activeStep = 8;
    if (this.$route.name == 'Stats') this.activeStep = 6;
    //  if (this.$route.name == 'NpsEO') this.activeStep = 9;
  },
  computed: {
    estateAddress() {
      return get(
        this.$store.state.azurestore,
        'estate.Address.StreetAddress',
        null
      );
    },
    steps() {
      var availableSteps = [];
      if (this.org == 'SMH' && this.theSource == 'Mspecs') {
        availableSteps = [
          { name: 'MINA SIDOR', id: 2 },
          { name: 'BUDGIVNING', id: 4 },
          { name: 'STATISTIK', id: 6 },
          { name: 'GENVÄGAR', id: 8 },
        ];
      }
      //EO och SMH
      else if (this.org == 'EO' || this.org == 'SMH' || this.org == 'IP') {
        availableSteps = [
          //  { name: 'INTAG', id: 1 },
          { name: 'MINA SIDOR', id: 2 },
          // { name: 'VISNING', id: 3 },
          { name: 'BUDGIVNING', id: 4 },
          { name: 'NPS', id: 5 },
          { name: 'STATISTIK', id: 6 },
          // { name: 'BÄSTA KOMMENTAREN', id: 7 },
          { name: 'GENVÄGAR', id: 8 },
          // { name: 'NPS_EO', id: 9 },
        ];
      } else {
        availableSteps = availableSteps = [
          { name: 'MINA SIDOR', id: 2 },
          { name: 'BUDGIVNING', id: 4 },
          { name: 'GENVÄGAR', id: 8 },
        ];
      }
      return availableSteps;
    },
  },
  methods: {
    goTo(stepId) {
      this.activeStep = stepId;
      if (stepId == 5 && this.org == 'EO') stepId = 9;
      if (stepId == 1) {
        this.$router.push({
          path: '/intake',
        });
      } else if (stepId == 2) {
        if (this.$route.name == 'MyPages') {
          this.alertDialogNoCancel(
            '',
            'Klicka på ikonen med tre hus om du vill se listan med alla bostäder',
            () => console.log('user ok')
          );
        }
        if (this.estateAddress) {
          this.$router.push({
            path: 'myPages',
          });
        } else {
          this.$router.push({
            path: 'brokerweb',
          });
        }
      } else if (stepId == 5) {
        this.$router.push({
          path: 'nps',
        });
      } else if (stepId == 4) {
        this.$router.push({
          path: 'bidding',
        });
        // } else if (stepId == 7) {
        //   this.$router.push({
        //     path: 'bestcomment',
        //   });
      } else if (stepId == 6) {
        this.$router.push({
          path: 'stats',
        });
      } else if (stepId == 8) {
        this.$router.push({
          path: 'shortcuts',
        });
      } else if (stepId == 9) {
        this.$router.push({
          path: 'nps_eo',
        });
      }
    },
  },
};
</script>

<style scoped></style>
