<template>
  <div style="width: 100%">
    <!--     NPS -->
    <v-container>
      <v-row width="100%">
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <v-card class="npsCard" flat>
            <v-row>
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <v-row>
                  <p>
                    <v-label>{{ npsHeader }}</v-label>
                  </p>
                </v-row>

                <v-row>
                  <br />
                  <v-label>Totalt antal svar: </v-label>
                  <v-label>{{ this.nbrAnswers }}</v-label>
                </v-row>
                <v-row>
                  <br />
                  <v-label>Ambassadörer: </v-label>
                  <v-label>{{ this.nbrPromoters }}</v-label>
                </v-row>
                <br />
                <v-row>
                  <v-label>Passiva: </v-label>
                  <v-label>{{ this.nbrPassives }}</v-label>
                  <br />
                </v-row>
                <br />
                <v-row>
                  <br />
                  <v-label>Kritiker: </v-label>
                  <v-label>{{ this.nbrDetractors }}</v-label> <br />
                </v-row>
                <v-row>
                  <br />
                  <b>
                    <v-label>NPS-värde: </v-label>
                    <v-label>{{ this.npsScore }}</v-label> <br
                  /></b>
                </v-row>
              </v-col>

              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <div>
                  <DoughnutChart
                    :width="200"
                    :height="200"
                    :data="chartDataNPS"
                    :backgroundColor="NPSColor"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <!--fördelning nps-->
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <v-card class="npsBarCard" flat>
            <v-row>
              <p>
                <v-label>FöRDELNING AV SVAR</v-label>
              </p>
            </v-row>

            <v-row>
              <b>
                <v-label>Medelvärde: </v-label>
                <v-label>{{ this.average }}</v-label> <br
              /></b>
            </v-row>
            <br />

            <div>
              <span>
                <BarChartH
                  :height="250"
                  :data="chartDataNpsBar"
                  :backgroundColor="NpsBarColor"
                  :labels="NpsBarLabels()"
                />
              </span>
            </div>
          </v-card>
        </v-col>
        <!--slut fördelning nps-->
      </v-row>
    </v-container>
  </div>
</template>
<script>
import mixin from '@/mixin.js';
import BarChartH from '@/components/charts/BarChartHorizontal.vue';
import DoughnutChart from '@/components/charts/Doughnut.vue';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
export default {
  name: 'NPS-results',
  props: { maxScale: String, statsType: String },
  mixins: [mixin],
  data() {
    return {
      NPSColor: ['#41B883', '#e6b400', '#E46651'], //#41B883', '#E46651', '#00D8FF
      chartDataNPS: [0, 0, 0], //promoters, passives, detractors

      NpsBarColor: [
        '#41B883',
        '#41B883',
        '#e6b400',
        '#e6b400',
        '#E46651',
        '#E46651',
        '#E46651',
        '#E46651',
        '#E46651',
        '#E46651',
        '#E46651',
      ],
      // NpsBarLabels: ['10', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0'],
      //  chartDataNpsBar: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      // statsBarColor: ['#bebebe'],
      // gradeLabel: [5, 4, 3, 2, 1],
    };
  },
  computed: {
    npsHeader() {
      if (this.statsType == 'BrokerNps' && this.org == 'SMH')
        return 'Betyg omräknat till NPS';
      else return 'NPS';
    },
    nbrAnswers() {
      return get(this.$store.state.infopingstore, 'statsData.Cnt', null);
    },
    nbrPromoters() {
      return get(
        this.$store.state.infopingstore,
        'statsData.CntPromoters',
        null
      );
    },
    nbrDetractors() {
      return get(
        this.$store.state.infopingstore,
        'statsData.CntDetractors',
        null
      );
    },
    nbrPassives() {
      return get(
        this.$store.state.infopingstore,
        'statsData.CntPassives',
        null
      );
    },
    npsScore() {
      var score = get(this.$store.state.infopingstore, 'statsData.Nps', null);
      //avrunda
      var rounded = Math.round(score * 10) / 10;
      return rounded;
    },
    average() {
      var average = get(
        this.$store.state.infopingstore,
        'statsData.Average',
        null
      );
      //avrunda
      var rounded = Math.round(average * 10) / 10;
      return rounded;
    },
    chartDataNpsBar() {
      var data = get(
        this.$store.state.infopingstore,
        'statsData.CntRating0_11',
        null
      );
      //ta bort position 6-11 i skalan när max är 5
      if (this.maxScale == '5') data = data.slice(0, 6);
      var sorted = cloneDeep(data);
      return sorted?.reverse();
    },
  },
  watch: {
    nbrAnswers() {
      this.calculateData();
    },
  },
  components: { BarChartH, DoughnutChart },
  mounted() {},
  methods: {
    calculateData() {
      this.chartDataNPS = [
        this.nbrPromoters,
        this.nbrPassives,
        this.nbrDetractors,
      ];
    },
    NpsBarLabels() {
      if (this.maxScale == '5') return ['5', '4', '3', '2', '1', '0'];
      else return ['10', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0'];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.npsCard {
  height: 100%;
  padding: 30px;
}
.npsBarCard {
  height: 100%;
  padding: 30px;
}
</style>
