<template>
  <div>
    <v-card>
      <v-card-title v-if="this.org == 'EO'">
        Skicka sms från Erik Olsson Fastighetsförmedling</v-card-title
      >
      <v-card-title v-else> Skicka sms från Demomäklaren</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="recipient"
          label="Skriv mobilnummer"
          outlined
        ></v-text-field>
        <v-select
          v-model="selectedTemplate"
          outlined
          label="Välj textmall"
          :items="messageTemplate"
        >
        </v-select>
        <br />
        <v-textarea
          label="Meddelande"
          outlined
          full-width
          textarea
          v-model="messageBody"
        >
        </v-textarea>
        Sms:et går inte att svara på.

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close()">Ångra</v-btn>
          <v-btn text @click="sendSMS()">Skicka</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
//import azureApi from '@/api/azureApi.js';
import mixin from '@/mixin.js';
//import get from 'lodash/get';
export default {
  name: 'SendSMS',
  mixins: [mixin],
  //props: { module: String },
  data() {
    return {
      messageBody: null,
      messageTemplate: ['Grattis'],
      selectedTemplate: null,
      recipient: null,
    };
  },
  components: {},
  watch: {
    selectedTemplate() {
      if (this.selectedTemplate == 'Grattis' && this.org == 'EO') {
        this.messageBody =
          'Grattis på födelsedagen!' +
          '\r\n' +
          'önskar Erik Olsson Fastighetsförmedling';
      } else if (this.selectedTemplate == 'Grattis' && this.org != 'EO') {
        this.messageBody =
          'Grattis på födelsedagen!' + '\r\n' + 'önskar Demomäklaren';
      }
    },
  },
  created() {},
  beforeMount() {},
  mounted() {},
  computed: {},
  methods: {
    close() {
      this.messageBody = null;
      this.selectedTemplate = null;
      this.recipient = null;
      this.$emit('closeDialog');
    },
    sendSMS() {
      // this.smsDialog = false;
      if (this.recipient && this.messageBody) {
        var params = {
          link: this.smsLink,
          phone_number: this.recipient,
          message: this.messageBody,
        };
        this.$store.dispatch('azurestore/sendSMS', params).then((res) => {
          if (res == 'ok') {
            this.close();
            this.alertDialogNoCancel('Skickat', 'Sms:et har skickats', () =>
              console.log('user ok')
            );
          } else
            this.alertDialogNoCancel(
              'Något gick fel',
              'SMS:et skickades inte. Försök igen igen. Felkod: ' + res,
              () => console.log('user ok')
            );
        });
      } else if (!this.recipient) {
        this.alertDialogNoCancel(
          'Mobilnummer saknas',
          'Du måste fylla i ett mobilnummer',
          () => console.log('user ok')
        );
      } else if (!this.messageBody) {
        this.alertDialogNoCancel('Text saknas', 'Skriv ett meddelande', () =>
          console.log('user ok')
        );
      }
    },
  },
};
</script>
<style></style>
