<template>
  <div>
    <v-container>
      <Description
        :url="headerImage"
        :header="header"
        :description="description"
      />

      <!--       <div class="shortcuts">
        <div v-for="item in fillShortcuts" :key="item.nbr">
          <img class="shortStyle" @click="login()" :src="item.image" />
        </div>
      </div> -->

      <div class="shortcuts">
        <div
          v-for="item in fillShortcuts"
          :key="item.nbr"
          class="shortcut-item"
          @click="login(item)"
        >
          <img class="shortStyle" :src="item.image" />
          <div class="text-overlay">
            <span class="item-text">{{ item.text }}</span>
          </div>
        </div>
      </div>
    </v-container>
    <v-dialog v-model="sendSmsDialog" max-width="500px">
      <SendSms @closeDialog="sendSmsDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import mixin from '@/mixin.js';
import Description from '@/components/description.vue';
import SendSms from '@/components/moduleButtons/sendTextMessage.vue';
import get from 'lodash/get';

export default {
  name: 'Shortcuts-Admin',
  mixins: [mixin],
  data() {
    return {
      header: 'Genvägar',
      description:
        'Här hittar du genvägar till de tjänster som inte administreras här i adminportalen.',
      headerImage:
        'https://statistik.boostad.net/img/BOOSTAD/IMAGE/portal_shortcuts.jpg',
      // shortcuts: [{ image: '', text: '', url: '' }],
      sendSmsDialog: false,
    };
  },
  async beforeMount() {
    this.$store.dispatch('infopingstore/clear_structure');
  },
  components: { Description, SendSms },

  computed: {
    officeManager() {
      var mgr = get(
        this.$store.state.infopingstore,
        'statsStructure.Companies',
        null
      );

      if (mgr && mgr.length > 0) return true;
      else return false;
    },

    fillShortcuts() {
      var shortcuts = [];
      if (this.org == 'IP') {
        shortcuts = [
          {
            image:
              'https://statistik.boostad.net/img/BOOSTAD/IMAGE/portal_twopeoplesign.jpg',
            text: 'Intagspresentation (kommer snart..)',
            url: 'https://boostad.net/intag',
            nbr: 1,
          },
          {
            image:
              'https://statistik.boostad.net/img/BOOSTAD/IMAGE/portal_wall.jpg',
            text: 'Visningsassistent',
            url: 'https://visning.boostad.net',
            nbr: 2,
          },
          {
            image:
              'https://statistik.boostad.net/img/BOOSTAD/IMAGE/portal_smsshortcut.jpg',
            text: 'Skicka sms',
            url: '',
            nbr: 3,
          },
        ];
        //smh mspecs
      } else if (this.org == 'SMH' && this.theSource == 'Mspecs') {
        shortcuts = [
          {
            image:
              'https://statistik.boostad.net/img/SMH/IMAGE/portal_intagshortcut.jpg',
            text: 'Intagspresentation',
            url: 'https://flippen.boostad.net',
            nbr: 1,
          },
        ];
        //SMH Vitec
      } else if (this.org == 'SMH') {
        shortcuts = [
          {
            image:
              'https://statistik.boostad.net/img/SMH/IMAGE/portal_intagshortcut.jpg',
            text: 'Intagspresentation',
            url: 'https://smhintag.boostad.net',
            nbr: 1,
          },
          {
            image:
              'https://statistik.boostad.net/img/BOOSTAD/IMAGE/portal_wall.jpg',
            text: 'Visningsassistent',
            url: 'https://visning.boostad.net',
            nbr: 2,
          },
        ];
        //eo
      } else if (this.org == 'EO') {
        shortcuts = [
          {
            image:
              'https://statistik.boostad.net/img/BOOSTAD/IMAGE/portal_wall.jpg',
            text: 'Visningsassistent',
            url: 'https://visning.boostad.net',
            nbr: 1,
          },
        ];
        if (this.officeManager)
          shortcuts.push({
            image:
              'https://statistik.boostad.net/img/BOOSTAD/IMAGE/portal_smsshortcut.jpg',
            text: 'Skicka sms',
            url: '',
            nbr: 2,
          });
        //era
      } else {
        shortcuts = [
          {
            image:
              'https://statistik.boostad.net/img/BOOSTAD/LOGOTYPE/full_logo_transparent.png',
            text: 'Inga tjänster aktiverade, klicka för att läsa mer',
            url: 'https://boostad.net/',
            nbr: 1,
          },
        ];
      }
      return shortcuts;
    },
  },

  async mounted() {
    if (this.org == 'EO') await this.getStructureSMS();
  },
  methods: {
    login(item) {
      if (item.text == 'Intagspresentation (kommer snart..)') {
        this.alertDialogNoCancel(
          '',
          'Nya intagspresentationen kommer att publiceras inom kort',
          () => console.log('user ok')
        );
      } else if (item.text == 'Skicka sms') {
        this.sendSmsDialog = true;
      } else if (item.url == 'https://boostad.net/') {
        window.open(item.url, '_blank');
      } else {
        var session = get(this.$store.state.infopingstore, 'session', []);
        window.open(item.url + '/?s=' + session, '_blank');
      }
    },
    async getStructureSMS() {
      var obj = {
        Session: get(this.$store.state.infopingstore, 'session', []),
        Group: 'officemanager',
        OnlyGroup: true,
      };

      var res = await this.$store.dispatch('infopingstore/statsStructure', obj);

      if (res?.status == 0) {
        this.alertDialogNoCancel('Inget svar', 'Felkod: ' + res.error, () =>
          console.log('user ok')
        );
      } else this.officeManager;
    },
  },
};
</script>
<style>
.shortcuts {
  display: flex;
  /* Add any additional styling for the container as needed */
}

.shortcut-item {
  position: relative;
  margin-right: 10px; /* Adjust margin as needed */
  cursor: pointer;
}

.shortStyle {
  max-width: 100%;
  height: 200px;
  /* Add any additional styling for the images as needed */
}
.shortcut-item:hover .shortStyle {
  opacity: 0.8; /* Adjust the opacity on hover */
}

.text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for transparency */
  padding: 10px;
  text-align: center;
}

.item-text {
  color: #fff; /* Text color */
  font-size: 14px; /* Adjust font size as needed */
}

@media screen and (max-width: 768px) {
  .shortcuts {
    display: block;
  }
  .shortcuts img {
    margin-top: 15px;
    align-items: stretch;
    height: auto;
    max-height: 200px;
    width: 100%;
    max-width: 100%;
  }
}
</style>
