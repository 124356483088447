<template>
  <div>
    <v-container>
      <Description
        :url="headerImage"
        :header="header"
        :description="description"
      />
      <EstateList module="brokerWeb" />
    </v-container>
  </div>
</template>

<script>
//import azureApi from '@/api/azureApi.js';
import mixin from '@/mixin.js';
import EstateList from '@/components/moduleButtons/estateList.vue';
import { mapGetters } from 'vuex';
import Description from '@/components/description.vue';
export default {
  name: 'Minasidor-Admin',
  mixins: [mixin],

  data() {
    return {
      header: 'Administrera Mina sidor',
      description: 'Välj en bostad.',
      headerImage:
        'https://statistik.boostad.net/img/BOOSTAD/IMAGE/portal_phonecomputer.jpg',
      brokerID: '',
    };
  },
  components: { Description, EstateList },
  watch: {},
  created() {},
  beforeMount() {},
  mounted() {
    this.$store.dispatch('azurestore/clearImageUrl');
  },
  computed: {
    ...mapGetters({
      customerPrefix: 'azurestore/customerPrefix',
    }),
  },
  methods: {},
};
</script>
<style></style>
